@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
  .forbackdiv {
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }
  .Step5try {
    display: block !important;
  }
  .step1-selection {
    margin-top: 24px;
  }
  .Selects-configu {
    position: relative;
    display: block;
    float: left;
    margin-left: 0px;

    padding-bottom: 16px;
  }
  .step1-configurator {
    min-height: unset !important;
    padding-bottom: 84px !important;
  }
  .Handrailselect .inoutchoice button {
    text-align: left;
  }
  #firstforbtn {
    margin-left: 16px !important;
    margin-right: 16px !important;
    width: 100% !important;
  }
  .forbtn {
    width: 100% !important;
  }
  .step2config h3 {
    margin-bottom: 24px;
  }
  .ConfiguratorMobileButton:last-child {
    margin-bottom: 85px !important;
  }
  .articletable tr:first-child td {
    padding-right: 8px !important;
  }
  .Selects-configu button img {
    max-width: 96px !important;
    max-height: 64px !important;
    padding: 0px !important;
  }
  .FrenchBalconySystemCard button img {
    max-width: unset !important;
    max-height: 95px !important;
  }
  #modelcanvas2 {
    width: 100% !important;
    height: 400px !important;
  }
  .ConfiguratorMobileButton h4 {
    margin-left: 16px;
    text-align: left;
  }
  .HeaderSlim #resp-menu {
    background-color: white;
  }

  .FrenchBalconyWrapStep4 button img {
    max-height: 150px !important;
  }
  .step1-configurator h3 {
    margin-top: 32px !important;
  }
  .bluecheck1 {
    display: none !important;
  }
  .ConfiguratorDesktopButton {
    display: none !important;
  }
  .ConfiguratorMobileButton button span {
    margin-left: 16px;
  }
  .ConfiguratorMobileButton button img {
    margin-right: 16px;
  }
  .ConfiguratorMobileButton button {
    display: flex !important;
    height: 85px !important;
    justify-content: space-between !important;
    align-items: center;
  }
  .ConfiguratorMobileButton {
    display: block !important;
  }
  .configuratorsteps {
    left: 50% !important;
    transform: translateX(-55%) !important;
  }
  .ConfiguratorMobileButton {
    display: block !important;
  }
  .rotationbox {
    left: unset !important;
    transform: unset !important;
    display: none;
    margin-left: 32px !important;
  }

  .rotationbox {
    left: 50%;
    transform: translate(-50%);
    top: 375px;
    margin-left: 40px;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 670px) {
}

@media only screen and (max-width: 768px) {
  .articletable tr td:last-child {
  }
  .articletable tr td {
    font-size: 13px;
  }
  .articlelist {
    overflow-x: scroll;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 320px) {
}
